import React, { useEffect, useState } from "react";

import { GeneratedProductItemProps } from "./GeneratedProductItem.props";
import styles from "./GeneratedProductItem.module.css";
import cn from "classnames";
import { P } from "common/P/P";
import WishlistButton from "components/WishlistButton/WishlistButton";
import { ReactComponent as VisualizeIcon } from "common/Icons/media_image_list.svg";
import Spinner from "modules/loaders/Spinner/Spinner";
import { serviceConfig } from "configs";
import { connect } from "react-redux";

const GeneratedProductItem = ({
	className,
	label,
	counter,
	productImage,
	mockupImageOne,
	mockupImageTwo,
	mockupImageThree,
	mockupImageFour,
	children,
	productPage = false,
	withoutMockups = false,
	onClick,
	desc,
	id,
	loading,
	loggedIn,
	...props
}: GeneratedProductItemProps): JSX.Element => {
	const [randomMessage, setRandomMessage] = useState("");

	const data = [
		"Creating your unique masterpiece...",
		"Generating your one-of-a-kind design...",
		"Bringing your vision to life...",
		"Crafting your personalized artwork...",
		"Generating the perfect artwork just for you...",
		"Transforming your ideas into stunning visuals...",
		"Loading AI creativity...",
	];

	useEffect(() => {
		const interval = setInterval(() => {
			const randomIndex = Math.floor(Math.random() * data.length);
			setRandomMessage(data[randomIndex]);
		}, 3000);

		return () => clearInterval(interval);
	}, []);

	const wishlistData = {
		_id: productImage?.generated_image_id,
		prompt: productImage?.prompt,
		url: productImage?.url,
	};

	return (
		<>
			{productPage ? (
				<div className={cn(styles.productcontainer, className)} {...props}>
					{productImage ? (
						<img src={productImage} alt="" className={`${cn(styles.image)}`} />
					) : (
						<Spinner />
					)}
					<P size="b1medium">{desc}</P>
					{loggedIn && (
						<WishlistButton
							isLargeIcon={true}
							type="image"
							id={id}
							data={wishlistData}
						/>
					)}
				</div>
			) : withoutMockups ? (
				<>
					<div
						className={cn(styles.withoutmockupcontainer, className)}
						{...props}
					>
						{productImage ? (
							<img
								src={`${serviceConfig.apiHost}${serviceConfig.apiPrefix}${productImage.url}`}
								// src={`data:image/jpeg;base64,${productImage}`}
								alt=""
								className={cn(styles.singleproductimage)}
								onClick={onClick}
							/>
						) : (
							<Spinner />
						)}
						{loggedIn && (
							<WishlistButton type="image" id={id} data={wishlistData} />
						)}
						{loading && (
							<div className={styles.spinnercontainer}>
								<P size="b2regular" className={cn(styles.spinnertext)}>
									{randomMessage}
								</P>
								<Spinner />
							</div>
						)}
					</div>
				</>
			) : (
				<div className={cn(styles.container, className)} {...props}>
					<div className={cn(styles.firstcol)}>
						<P size="b1medium">{label}</P>
						<div className={cn(styles.productimage)}>
							{productImage ? (
								<img
									src={`${serviceConfig.apiHost}${serviceConfig.apiPrefix}${productImage.url}`}
									// src={`data:image/jpeg;base64,${productImage}`}
									alt=""
									className={cn(styles.image)}
								/>
							) : (
								<Spinner />
							)}
							{loggedIn && (
								<WishlistButton type="image" id={id} data={wishlistData} />
							)}
						</div>
					</div>
					<div className={cn(styles.secondcol)}>
						<P size="b1underline">{counter}</P>
						<div className={cn(styles.mockupimages)}>
							{children ? (
								children
							) : (
								<>
									<img
										src={mockupImageOne}
										alt=""
										className={cn(styles.mockups)}
									/>
									<img
										src={mockupImageTwo}
										alt=""
										className={cn(styles.mockups)}
									/>
									<img
										src={mockupImageThree}
										alt=""
										className={cn(styles.mockups)}
									/>
									<img
										src={mockupImageFour}
										alt=""
										className={cn(styles.mockups)}
									/>
								</>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		loggedIn: state.auth.loggedIn,
	};
};

export default connect(mapStateToProps)(GeneratedProductItem);
