/* eslint-disable no-mixed-spaces-and-tabs */
import { ErrorBoundary } from "modules/errors";
import { Button } from "common/Button/Button";
import { P } from "common/P/P";

import { connect } from "react-redux";

import cn from "classnames";
import { addWishlist, deleteWishlist } from "airedux/actions/wishlist.actions";

import styles from "common/ProductImageItem/ProductImageItem.module.css";
import { ReactComponent as WishlistIcon } from "common/Icons/heart.svg";
import { ReactComponent as WishlistFilledIcon } from "common/Icons/heart-filled.svg";

const isWishlisted = (
	state: any,
	id: any,
	type: any,
	productId?: string,
	variantId?: string
) => {
	// Extract the user's wishlists directly from the state structure
	const wishlists = state.auth.data?.data?.[0]?.wishlists || {
		images: [],
		products: [],
	};

	console.log("Wishlist", id, type);

	// Determine the type of wishlist to search (image or product)
	if (type === "image") {
		// Search in the images array for an item with the matching _id
		return wishlists.images.some((image: any) => image.content.image_id === id);
	} else if (type === "product") {
		// If products have a different identifier, adjust accordingly
		// Assuming product _id is used for matching as well
		return wishlists.products.some(
			(product: any) =>
				product.content.variant_id === variantId &&
				product.content.product_id === productId
		);
	}

	// Default return false if type does not match
	return false;
};

const WishlistButton = ({
	id,
	userId,
	data,
	isLargeIcon,
	isWishlisted,
	component_type,
	onAdd,
	onRemove,
	loggedIn,
	button_styles,
	type,
	wishlistId,
}: any) => {
	console.log("---- Wishlist", id);
	const sendingData = {
		userId: userId,
		type: type,
		content:
			type === "product"
				? {
						product_id: data?.product_id,
						variant_id: data?.variant_id,
				  }
				: {
						image_id: data?._id,
						prompt: data?.prompt,
						url: data?.url,
				  },
	};

	const handleAdd = async (event: any) => {
		event.preventDefault();
		if (loggedIn) {
			onAdd(sendingData);
		}
	};

	const handleRemove = async (event: any) => {
		event.preventDefault();
		if (loggedIn && wishlistId) {
			onRemove(wishlistId, type); // Pass the wishlist ID for deletion
		}
	};
	return isLargeIcon ? (
		<Button
			appearance="ghost-white"
			icon={isWishlisted ? <WishlistFilledIcon /> : <WishlistIcon />}
			onClick={isWishlisted ? handleRemove : handleAdd}
		>
			<P size="b1regular">
				{!isWishlisted ? "Add to favorites" : "Remove from favorites"}
			</P>
		</Button>
	) : component_type === "button" ? (
		<Button
			appearance="ghost-white"
			className={button_styles ?? cn(styles.icondiv)}
			onClick={isWishlisted ? handleRemove : handleAdd}
		>
			{isWishlisted ? (
				<WishlistFilledIcon />
			) : (
				<WishlistIcon className={button_styles ?? cn(styles.icon)} />
			)}
		</Button>
	) : (
		<span
			className={button_styles ?? cn(styles.icondiv)}
			onClick={isWishlisted ? handleRemove : handleAdd}
		>
			{isWishlisted ? (
				<WishlistFilledIcon />
			) : (
				<WishlistIcon className={cn(styles.icon)} />
			)}
		</span>
	);
};

const mapStateToProps = (state: any, props: any) => {
	let userId = "null";
	if (
		state.auth.data &&
		Array.isArray(state.auth.data.data) &&
		state.auth.data.data.length > 0 &&
		state.auth.data.data[0].rp_acc
	) {
		userId = state.auth.data.data[0].rp_acc._id;
	}
	const { data, type } = props;

	// Function to find wishlist ID
	const findWishlistId = (
		state: any,
		dataId: string,
		type: "image" | "product",
		productId?: string,
		variantId?: string
	) => {
		if (type === "image") {
			const wishlistItem = state.wishlist.images.find(
				(image: any) => image.content.image_id === dataId
			);
			return wishlistItem?._id; // Return the wishlist ID if found
		} else {
			// Assuming you can find a unique identifier in your products array
			const wishlistItem = state.wishlist.products.find(
				(product: any) =>
					product.content.variant_id === variantId &&
					product.content.product_id === productId
			);
			console.log("RETURN IS WISHED", wishlistItem);
			return wishlistItem?._id; // Return the wishlist ID if found
		}
	};
	const idForWishlistFinding =
		type === "product" ? data?.variant_id : data?._id;
	return {
		isWishlisted: isWishlisted(
			state,
			idForWishlistFinding,
			type,
			data?.product_id,
			data?.variant_id
		),
		loggedIn: state.auth.loggedIn,
		userId: userId,
		wishlistId: findWishlistId(
			state,
			idForWishlistFinding,
			type,
			data?.product_id,
			data?.variant_id
		), // Pass the wishlist ID for removal
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	onAdd: (payload: any) => dispatch(addWishlist(payload)),
	onRemove: (payload: any, type: any) =>
		dispatch(deleteWishlist(payload, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WishlistButton);
